exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-careers-cv-sent-index-tsx": () => import("./../../../src/pages/careers/cv-sent/index.tsx" /* webpackChunkName: "component---src-pages-careers-cv-sent-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-cases-index-tsx": () => import("./../../../src/pages/cases/index.tsx" /* webpackChunkName: "component---src-pages-cases-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-error-sent-index-tsx": () => import("./../../../src/pages/error-sent/index.tsx" /* webpackChunkName: "component---src-pages-error-sent-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-amazon-web-services-aws-cloud-dev-index-tsx": () => import("./../../../src/pages/industries/amazon-web-services-aws-cloud-dev/index.tsx" /* webpackChunkName: "component---src-pages-industries-amazon-web-services-aws-cloud-dev-index-tsx" */),
  "component---src-pages-industries-apple-vision-pro-app-development-services-index-tsx": () => import("./../../../src/pages/industries/apple-vision-pro-app-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-apple-vision-pro-app-development-services-index-tsx" */),
  "component---src-pages-industries-cloud-cost-optimization-services-index-tsx": () => import("./../../../src/pages/industries/cloud-cost-optimization-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-cloud-cost-optimization-services-index-tsx" */),
  "component---src-pages-industries-ecommerce-software-development-services-index-tsx": () => import("./../../../src/pages/industries/ecommerce-software-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-ecommerce-software-development-services-index-tsx" */),
  "component---src-pages-industries-education-software-development-services-index-tsx": () => import("./../../../src/pages/industries/education-software-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-education-software-development-services-index-tsx" */),
  "component---src-pages-industries-ehr-and-emr-software-development-index-tsx": () => import("./../../../src/pages/industries/ehr-and-emr-software-development/index.tsx" /* webpackChunkName: "component---src-pages-industries-ehr-and-emr-software-development-index-tsx" */),
  "component---src-pages-industries-fintech-software-development-services-index-tsx": () => import("./../../../src/pages/industries/fintech-software-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-fintech-software-development-services-index-tsx" */),
  "component---src-pages-industries-fitness-app-development-services-index-tsx": () => import("./../../../src/pages/industries/fitness-app-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-fitness-app-development-services-index-tsx" */),
  "component---src-pages-industries-health-information-exchange-software-index-tsx": () => import("./../../../src/pages/industries/health-information-exchange-software/index.tsx" /* webpackChunkName: "component---src-pages-industries-health-information-exchange-software-index-tsx" */),
  "component---src-pages-industries-health-med-tech-index-tsx": () => import("./../../../src/pages/industries/health-med-tech/index.tsx" /* webpackChunkName: "component---src-pages-industries-health-med-tech-index-tsx" */),
  "component---src-pages-industries-hr-software-development-services-index-tsx": () => import("./../../../src/pages/industries/hr-software-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-hr-software-development-services-index-tsx" */),
  "component---src-pages-industries-index-tsx": () => import("./../../../src/pages/industries/index.tsx" /* webpackChunkName: "component---src-pages-industries-index-tsx" */),
  "component---src-pages-industries-media-and-entertainment-software-development-index-tsx": () => import("./../../../src/pages/industries/media-and-entertainment-software-development/index.tsx" /* webpackChunkName: "component---src-pages-industries-media-and-entertainment-software-development-index-tsx" */),
  "component---src-pages-industries-mental-health-app-development-services-index-tsx": () => import("./../../../src/pages/industries/mental-health-app-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-mental-health-app-development-services-index-tsx" */),
  "component---src-pages-industries-oculus-meta-quest-app-development-services-index-tsx": () => import("./../../../src/pages/industries/oculus-meta-quest-app-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-oculus-meta-quest-app-development-services-index-tsx" */),
  "component---src-pages-industries-retail-software-development-services-index-tsx": () => import("./../../../src/pages/industries/retail-software-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-retail-software-development-services-index-tsx" */),
  "component---src-pages-industries-serverless-application-development-services-index-tsx": () => import("./../../../src/pages/industries/serverless-application-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-serverless-application-development-services-index-tsx" */),
  "component---src-pages-industries-sports-app-development-company-index-tsx": () => import("./../../../src/pages/industries/sports-app-development-company/index.tsx" /* webpackChunkName: "component---src-pages-industries-sports-app-development-company-index-tsx" */),
  "component---src-pages-industries-telemedicine-app-development-services-index-tsx": () => import("./../../../src/pages/industries/telemedicine-app-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-telemedicine-app-development-services-index-tsx" */),
  "component---src-pages-industries-wellness-app-development-services-index-tsx": () => import("./../../../src/pages/industries/wellness-app-development-services/index.tsx" /* webpackChunkName: "component---src-pages-industries-wellness-app-development-services-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-request-sent-index-tsx": () => import("./../../../src/pages/request-sent/index.tsx" /* webpackChunkName: "component---src-pages-request-sent-index-tsx" */),
  "component---src-pages-services-custom-lms-development-index-tsx": () => import("./../../../src/pages/services/custom-lms-development/index.tsx" /* webpackChunkName: "component---src-pages-services-custom-lms-development-index-tsx" */),
  "component---src-pages-services-custom-software-development-index-tsx": () => import("./../../../src/pages/services/custom-software-development/index.tsx" /* webpackChunkName: "component---src-pages-services-custom-software-development-index-tsx" */),
  "component---src-pages-services-devops-index-tsx": () => import("./../../../src/pages/services/devops/index.tsx" /* webpackChunkName: "component---src-pages-services-devops-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-legacy-code-refactoring-index-tsx": () => import("./../../../src/pages/services/legacy-code-refactoring/index.tsx" /* webpackChunkName: "component---src-pages-services-legacy-code-refactoring-index-tsx" */),
  "component---src-pages-services-mobile-app-development-index-tsx": () => import("./../../../src/pages/services/mobile-app-development/index.tsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-index-tsx" */),
  "component---src-pages-services-mvp-development-index-tsx": () => import("./../../../src/pages/services/mvp-development/index.tsx" /* webpackChunkName: "component---src-pages-services-mvp-development-index-tsx" */),
  "component---src-pages-services-saas-development-index-tsx": () => import("./../../../src/pages/services/saas-development/index.tsx" /* webpackChunkName: "component---src-pages-services-saas-development-index-tsx" */),
  "component---src-pages-services-software-code-audit-index-tsx": () => import("./../../../src/pages/services/software-code-audit/index.tsx" /* webpackChunkName: "component---src-pages-services-software-code-audit-index-tsx" */),
  "component---src-pages-services-ui-ux-design-index-tsx": () => import("./../../../src/pages/services/ui-ux-design/index.tsx" /* webpackChunkName: "component---src-pages-services-ui-ux-design-index-tsx" */),
  "component---src-pages-services-web-development-index-tsx": () => import("./../../../src/pages/services/web-development/index.tsx" /* webpackChunkName: "component---src-pages-services-web-development-index-tsx" */),
  "component---src-pages-technologies-django-development-company-index-tsx": () => import("./../../../src/pages/technologies/django-development-company/index.tsx" /* webpackChunkName: "component---src-pages-technologies-django-development-company-index-tsx" */),
  "component---src-pages-technologies-node-js-development-company-index-tsx": () => import("./../../../src/pages/technologies/node-js-development-company/index.tsx" /* webpackChunkName: "component---src-pages-technologies-node-js-development-company-index-tsx" */),
  "component---src-pages-technologies-python-development-company-index-tsx": () => import("./../../../src/pages/technologies/python-development-company/index.tsx" /* webpackChunkName: "component---src-pages-technologies-python-development-company-index-tsx" */),
  "component---src-pages-technologies-react-js-development-services-index-tsx": () => import("./../../../src/pages/technologies/react-js-development-services/index.tsx" /* webpackChunkName: "component---src-pages-technologies-react-js-development-services-index-tsx" */),
  "component---src-pages-technologies-ruby-on-rails-development-company-index-tsx": () => import("./../../../src/pages/technologies/ruby-on-rails-development-company/index.tsx" /* webpackChunkName: "component---src-pages-technologies-ruby-on-rails-development-company-index-tsx" */),
  "component---src-pages-technologies-shopify-development-services-index-tsx": () => import("./../../../src/pages/technologies/shopify-development-services/index.tsx" /* webpackChunkName: "component---src-pages-technologies-shopify-development-services-index-tsx" */),
  "component---src-pages-technologies-vue-js-development-company-index-tsx": () => import("./../../../src/pages/technologies/vue-js-development-company/index.tsx" /* webpackChunkName: "component---src-pages-technologies-vue-js-development-company-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-templates-author-author-tsx": () => import("./../../../src/templates/author/author.tsx" /* webpackChunkName: "component---src-templates-author-author-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-list-blog-list-tag-tsx": () => import("./../../../src/templates/blog-list/blog-list-tag.tsx" /* webpackChunkName: "component---src-templates-blog-list-blog-list-tag-tsx" */),
  "component---src-templates-blog-list-blog-list-tsx": () => import("./../../../src/templates/blog-list/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-blog-list-tsx" */),
  "component---src-templates-case-case-tsx": () => import("./../../../src/templates/case/case.tsx" /* webpackChunkName: "component---src-templates-case-case-tsx" */),
  "component---src-templates-provider-redirect-tsx": () => import("./../../../src/templates/providerRedirect.tsx" /* webpackChunkName: "component---src-templates-provider-redirect-tsx" */),
  "component---src-templates-vacancy-vacancy-tsx": () => import("./../../../src/templates/vacancy/vacancy.tsx" /* webpackChunkName: "component---src-templates-vacancy-vacancy-tsx" */)
}

